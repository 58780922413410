<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__safetyqms">
      <el-form :inline="true" :model="dataForm">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
      </el-form>
      <el-tabs type="border-card" v-model="activeName" @tab-click="tabClickHandle">
        <el-tab-pane label="组织框图" name="组织框图">
          <el-button type="primary" size="mini" @click="openUploadPopup('tabUpload1')" v-if="$hasPermission('opm:safetyqms:update')">上传文件</el-button>
          <el-button type="danger" size="mini" @click="deleteAttachmentHandle" v-if="$hasPermission('opm:safetyqms:update')">删除</el-button>
          <el-upload ref="tabUpload1" accept="image/jpg,image/jpeg,image/png" :disabled="!$hasPermission('opm:safetyqms:update')" :on-progress="onProgressHandle" :action="actionUrl1" :file-list="fileList1"
                     :on-success="handleSuccess" :show-file-list="false" :before-upload="checkFileType" :auto-upload="true">
          </el-upload>
          <el-image v-if="imageUrl1" class="my-img-style" :src="imageUrl1" fit="contain" :preview-src-list="previewImageUrl1">
            <div slot="placeholder">图片加载中...</div>
          </el-image>
        </el-tab-pane>
        <el-tab-pane label="岗位职责分工" name="岗位职责分工">
          <my-upload ref="tabUpload2" :view="!$hasPermission('opm:safetyqms:update')" :refId="myRefId" :typeId="typeIdList[1]" :tableMaxHeight="uploadTableMaxHeight" :beforeFileUploadFunc="checkRefIdCreated"></my-upload>
        </el-tab-pane>
        <el-tab-pane label="安全保证体系框图" name="安全保证体系框图">
          <el-button type="primary" size="mini" @click="openUploadPopup('tabUpload3')" v-if="$hasPermission('opm:safetyqms:update')">上传文件</el-button>
          <el-button type="danger" size="mini" @click="deleteAttachmentHandle" v-if="$hasPermission('opm:safetyqms:update')">删除</el-button>
          <el-upload ref="tabUpload3" accept="image/jpg,image/jpeg,image/png" :action="actionUrl3" :file-list="fileList3" :show-file-list="false" :before-upload="checkFileType"
                     :disabled="!$hasPermission('opm:safetyqms:update')" list-type="picture" :on-success="handleSuccess"
                     :on-progress="onProgressHandle" :auto-upload="true">
          </el-upload>
          <el-image v-if="imageUrl3" class="my-img-style" :src="imageUrl3" fit="contain" :preview-src-list="previewImageUrl3">
            <div slot="placeholder">图片加载中...</div>
          </el-image>
        </el-tab-pane>
        <el-tab-pane label="领导机构文件" name="领导机构文件">
          <my-upload ref="tabUpload4" :view="!$hasPermission('opm:safetyqms:update')" :refId="myRefId" :typeId="typeIdList[3]" :tableMaxHeight="uploadTableMaxHeight" :beforeFileUploadFunc="checkRefIdCreated"></my-upload>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-card>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      dataForm: {
        prjId: '',
        contractId: '',
      },
      activeName: '组织框图',
      typeIdList: ['组织框图', '岗位职责分工', '安全保证体系框图', '领导机构文件'],
      myRefId: '',

      // 组织框图
      fileList1: [],
      actionUrl1: '',
      imageUrl1: '',

      // 安全保证体系框图
      fileList3: [],
      actionUrl3: '',
      imageUrl3: '',

      uploadTableMaxHeight: 200
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.uploadTableMaxHeight = window.innerHeight - 280
    })
  },
  computed: {
    // 组织框图大图预览Url
    previewImageUrl1: function () {
      return this.imageUrl1 ? [this.imageUrl1] : []
    },
    // 安全保证体系框图大图预览Url
    previewImageUrl3: function () {
      return this.imageUrl3 ? [this.imageUrl3] : []
    }
  },
  methods: {
    // 标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.myRefId = ''
      this.clearData()
      const loading = this.$loading({
        lock: true,
        text: '运行中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 获取该标段下的安全管理体系
      this.$http.get('/opm/safetyQms/getByContractId/' + this.dataForm.contractId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data) {
          this.myRefId = res.data.id
          this.actionUrl1 = this.getUrl(this.myRefId, this.typeIdList[0])
          this.actionUrl3 = this.getUrl(this.myRefId, this.typeIdList[2])
        }
        this.loadFile()
      }).catch(() => {
        // do nothing
      }).finally(() => {
        loading.close()
      })
    },


    getUrl(refId, typeId) {
      return `${window.SITE_CONFIG['apiURL']}/oss/file/upload?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}&refId=${refId}&typeId=${typeId}&statusId=1`
    },

    clearData() {
      this.imageUrl1 = ''
      this.imageUrl3 = ''
      this.fileList1 = []
      this.fileList3 = []
    },

    tabClickHandle() {
      if (this.activeName == this.typeIdList[0] || this.activeName == this.typeIdList[2]) {
        this.loadFile()
      }
    },

    loadFile(loading) {
      this.clearData()
      if (this.myRefId) {
        if (!loading) {
          loading = this.$loading({
            lock: true,
            text: '运行中...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        }
        this.$http.get(`/oss/file/list/${this.myRefId}?typeId=${this.activeName}`)
            .then(({data: res}) => {
              if (res.code === 0) {
                if (this.activeName == this.typeIdList[0]) {
                  this.fileList1 = res.data
                  this.imageUrl1 = res.data ? res.data[0].signedUrl : '' // 图片预览只取第一个附件
                } else if (this.activeName == this.typeIdList[2]) {
                  this.fileList3 = res.data
                  this.imageUrl3 = res.data ? res.data[0].signedUrl : '' // 图片预览只取第一个附件
                }
              }
            })
            .catch(() => {
              // do nothing
            }).finally(() => {
          loading.close()
        })
      }
    },

    // 打开附件上传文件框
    openUploadPopup(refUploadName) {
      if (this.checkRefIdCreated() && refUploadName) {
        // 触发upload组件内部点击事件，弹出文件选择框
        this.$refs[refUploadName].$refs['upload-inner'].handleClick()
      }
    },

    // 附件上传前,检查用于附件上传的RefId(即管理体系主表ID)是否已经创建
    checkRefIdCreated() {
      if (!this.myRefId) {
        this.$message.error("系统自动创建管理体系主表出错了，请刷新页面重试!")
        return false
      } else {
        return true
      }
    },

    deleteAttachmentHandle() {
      if (this.activeName == this.typeIdList[0]) {
        if (this.fileList1.length <= 0) {
          return this.$message({
            message: '尚未上传' + this.activeName,
            type: 'warning'
          });
        }
      } else if (this.activeName == this.typeIdList[2]) {
        if (this.fileList3.length <= 0) {
          return this.$message({
            message: '尚未上传' + this.activeName,
            type: 'warning'
          });
        }
      }
      this.$confirm('确定删除' + this.activeName + '吗?', this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.handleSuccess()
      }).catch(() => {
        // do nothing
      })
    },

    // 上传中，显示loading
    onProgressHandle(event, file, fileList) {
      this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },

    handleSuccess(res, file, fileList) {
      const loading = this.$loading({
        lock: true,
        text: '运行中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let deleteIds = []
      // 删除组织框图、安全保证体系框图，除本次上传外的其他附件
      if (this.activeName == this.typeIdList[0]) {
        for (let i = 0; i < this.fileList1.length; i++) {
          deleteIds.push(this.fileList1[i].id)
        }
      } else if (this.activeName == this.typeIdList[2]) {
        for (let i = 0; i < this.fileList3.length; i++) {
          deleteIds.push(this.fileList3[i].id)
        }
      }
      if (deleteIds.length > 0) {
        this.$http.delete(
            '/oss/file',
            {
              'data': deleteIds
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
        }).catch(() => {
          // do nothing
        }).finally(() => {
          // 上传成功后，重新读取服务器数据
          this.loadFile(loading)
          if (loading) {
            loading.close()
          }
        })
      } else {
        // 上传成功后，重新读取服务器数据
        this.loadFile(loading)
      }
    },

    // 附件上传前 文件类型检查
    checkFileType(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension4 = testmsg === 'jpeg'
      if (!extension && !extension2 && !extension4) {
        this.$message({
          message: '只能上传 jpg, jpeg, png格式的图片!',
          type: 'warning'
        })
        return false
      } else {
        return true
      }
    },
  }
}
</script>
<style scoped>
.my-img-style {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 270px);
}
</style>