<template>
  <el-dialog :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-form-item label="资料名称" prop="name">
        <el-input v-model="dataForm.name" readonly></el-input>
      </el-form-item>
      <el-form-item prop="content" label="资料简介">
        <!-- 富文本编辑器, 容器 -->
        <div id="J_quillEditor" style="height:280px"></div>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="资料分类">
            <dict-select v-model="dataForm.typeId" dict-type="help_type" placeholder="请选择" style="width: 100%;" :disabled="true"></dict-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="资料版本">
            <el-input v-model="dataForm.version" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="status" label="是否公开" size="mini">
            <el-radio-group v-model="dataForm.open" disabled>
              <el-radio :label="0">保密</el-radio>
              <el-radio :label="1">公开</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="识别编码">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <my-upload ref="upload" :refId="dataForm.id" view></my-upload>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import Cookies from 'js-cookie'
import 'quill/dist/quill.snow.css'
import Quill from 'quill'
export default {
  data () {
    return {
      visible: false,
      quillEditor: null,
      quillEditorToolbarOptions: [
        ['bold', 'italic', 'underline', 'strike'],
        ['image'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
      ],
      dataForm: {
        id: '',
        code: '',
        name: '',
        typeId: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: ''
      }
    }
  },
  methods: {
   init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.quillEditor) {
          this.quillEditor.deleteText(0, this.quillEditor.getLength())
        } else {
          this.quillEditorHandle()
        }
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 富文本编辑器
    quillEditorHandle () {
      this.quillEditor = new Quill('#J_quillEditor', {
        modules: {
          toolbar: this.quillEditorToolbarOptions
        },
        theme: 'snow',
        readOnly: true
      })
      // 自定义上传图片功能 (使用element upload组件)
      this.uploadUrl = `${window.SITE_CONFIG['apiURL']}/oss/file/uploadPhoto?access_token=${Cookies.get('access_token')}&tenant_code_key=${Cookies.get('tenantCode')}`
      this.quillEditor.getModule('toolbar').addHandler('image', () => {
        this.$refs.uploadBtn.$el.click()
      })
      // 监听内容变化，动态赋值
      this.quillEditor.on('text-change', () => {
        this.dataForm.content = this.quillEditor.root.innerHTML
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/sys/help/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.quillEditor.root.innerHTML = this.dataForm.content
      }).catch(() => {
      })
    }
  }
}
</script>
