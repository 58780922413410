<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__safetyrr">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="乙方姓名" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.duty" placeholder="职务\工种" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if='dataForm.contractId' style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:safetyrr:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:safetyrr:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="乙方姓名" min-width="150" align="left" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" @click="uploadDrawerHandle(scope.row.id, [], !$hasPermission('opm:safetyrr:update'))">{{scope.row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="duty" label="职务\工种" width="250" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="160">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:safetyrr:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:safetyrr:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:safetyrr:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './safetyrr-add-or-update'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/safetyRr/page',
        getDataListIsPage: true,
        exportURL: '/opm/safetyRr/export',
        deleteURL: '/opm/safetyRr',
        createdIsNeed: false
      },
      dataForm: {
        prjId:'',
        contractId:'',
        name: '',
        duty: '',
      },
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
  },
  methods: {
    // 标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },

    // 新增 / 修改
    addOrUpdateHandle (id) {
      // 组件传入了不同的参数，重写混入的方法
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.init()
      })
    },

  }
}
</script>