<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__major">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="资料名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <dict-select v-model="dataForm.typeId" dict-type="help_type" placeholder="请选择分类" style="width: 100%;" @change="typeChangeHandle"></dict-select>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('sys:help:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('sys:help:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle" style="width: 100%;">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="名称" align="left" show-overflow-tooltip sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="typeId" label="资料分类" align="center" show-overflow-tooltip sortable="custom">
          <template slot-scope="scope">
            {{scope.row.typeName}}
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center" show-overflow-tooltip sortable="custom" width="200"></el-table-column>
        <el-table-column prop="creatorName" label="创建人" align="center" show-overflow-tooltip width="150"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="160">
          <template slot-scope="scope">
            <table-button role="update" v-if="$hasPermission('sys:help:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('sys:help:delete')" @click="deleteHandle(scope.row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(scope.row.id, [], !$hasPermission('sys:help:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './help-add-or-update'
import ViewPage from './help-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/sys/help/page',
        getDataListIsPage: true,
        deleteURL: '/sys/help',
      },
      dataForm: {
        name: '',
        typeId: ''
      }
    }
  },
  methods: {
    // 下拉框改变事件
    typeChangeHandle (data) {
      this.getDataList()
    }
  },
  components: {
    AddOrUpdate,
    ViewPage
  }
}
</script>

